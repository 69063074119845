<template>
  <section class="honoree-form">
    <form @submit="handleFormSubmit">
      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title">
          {{ translations.fieldSections.surveySection.title }}
        </legend>
        <span
          class="honoree-form__subsection__subtitle honoree-form__subsection__subtitle--paragraph"
        >
          {{ translations.fieldSections.surveySection.question }}
        </span>
        <div class="honoree-form__checkbox-group -vertical col-lg-12">
          <label
            v-for="(option, $key) in translations.fieldSections.surveySection.answers"
            :key="$key"
            :for="option.id"
            class="honoree-form__checkbox__wrapper -nest"
          >
            <input
              :id="option.id"
              ref="input"
              v-model="surveyAnswers"
              class="honoree-form__checkbox__input"
              :checkboxDisabled="surveyAnswers.length >= 9999 && !surveyAnswers.includes(option.id)"
              :value="option.id"
              type="checkbox"
              :aria-invalid="!checkboxIsValid"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ option.label }}
            <HonoreeFormInputField
              v-if="isOtherSelected && option.id === 'Other'"
              id="survey-sources-other"
              v-model="otherSourcesInputValue"
              class="col-lg-3 -nested"
              :label="translations.fieldSections.surveySection.othersInputLabel"
              :initial-data="initialData.data.otherSourcesInputValue"
              :error-message="translations.fieldSections.surveySection.requiredFieldMessage"
              :character-limit="translations.fieldSections.surveySection.maxLength.count"
              :max-character-message="translations.fieldSections.surveySection.maxLength.message"
            />
            <HonoreeFormInputField
              v-if="isSocialMediaSelected && option.id === 'SocialMedia'"
              id="survey-sources-social-media"
              v-model="socialMediaInputValue"
              class="col-lg-3 -nested"
              :label="translations.fieldSections.surveySection.socialMediaInputLabel"
              :initial-data="initialData.data.socialMediaInputValue"
              :error-message="translations.fieldSections.surveySection.requiredFieldMessage"
              :character-limit="translations.fieldSections.surveySection.maxLength.count"
              :max-character-message="translations.fieldSections.surveySection.maxLength.message"
            />
          </label>
        </div>
        <span v-if="!checkboxIsValid" id="survey-section--error" class="honoree-form__error-state">
          {{ translations.fieldSections.surveySection.requiredFieldMessage }}
        </span>
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title--paragraph">
          {{ translations.fieldSections.optInMailingWowVolunteeringSection.question }}
        </legend>

        <div class="honoree-form__radio-group">
          <label for="opt-in-mailing-wow-volunteering-yes" class="honoree-form__checkbox__wrapper">
            <input
              id="opt-in-mailing-wow-volunteering-yes"
              v-model="optInMailingWowVolunteeringValue"
              class="honoree-form__checkbox__input"
              name="opt-in-mailing-wow-volunteering-yes"
              type="radio"
              :value="true"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.fieldSections.optInMailingWowVolunteeringSection.options.yes.label }}
          </label>
          <label for="opt-in-mailing-wow-volunteering-no" class="honoree-form__checkbox__wrapper">
            <input
              id="opt-in-mailing-wow-volunteering-no"
              v-model="optInMailingWowVolunteeringValue"
              class="honoree-form__checkbox__input"
              name="opt-in-mailing-wow-volunteering-no"
              type="radio"
              :value="false"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.fieldSections.optInMailingWowVolunteeringSection.options.no.label }}
          </label>
        </div>
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title--paragraph">
          {{ translations.fieldSections.optInMailingLorealSocietySection.question }}
        </legend>

        <div class="honoree-form__radio-group">
          <label for="opt-in-mailing-loreal-society-yes" class="honoree-form__checkbox__wrapper">
            <input
              id="opt-in-mailing-loreal-society-yes"
              v-model="optInMailingLorealSocietyValue"
              class="honoree-form__checkbox__input"
              name="opt-in-mailing-loreal-society-yes"
              type="radio"
              :value="true"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.fieldSections.optInMailingLorealSocietySection.options.yes.label }}
          </label>
          <label for="opt-in-mailing-loreal-society-no" class="honoree-form__checkbox__wrapper">
            <input
              id="opt-in-mailing-loreal-society-no"
              v-model="optInMailingLorealSocietyValue"
              class="honoree-form__checkbox__input"
              name="opt-in-mailing-loreal-society-no"
              type="radio"
              :value="false"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.fieldSections.optInMailingLorealSocietySection.options.no.label }}
          </label>
        </div>
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title">
          {{ translations.fieldSections.awardSection.title }}
        </legend>
        <span
          class="honoree-form__subsection__subtitle honoree-form__subsection__subtitle--paragraph"
        >
          {{ translations.fieldSections.awardSection.question }}
        </span>
      </fieldset>

      <hr class="honoree-form__hr" />

      <div class="honoree-form__buttons-wrapper">
        <button
          class="wow-button wow-button--black"
          type="button"
          @click="handleFormSave"
          v-text="formLabels.saveButton.label"
        />
        <button
          class="wow-button wow-button--black"
          @click="triggerValidation"
          v-text="`${formLabels.preview.label}`"
        />
      </div>
      <div>
        <button
          class="wow-button"
          type="button"
          @click.prevent="goToPreviousPage"
          v-text="`< ${formLabels.previousButton.label}`"
        />
      </div>
    </form>
  </section>
</template>

<script>
import { nextTick } from 'vue';
import eventBus from '@loreal/eventbus-js';
import HonoreeFormInputField from './HonoreeFormInputField.vue';
import {
  VALIDATE_CHILD_EVENT,
  SURVEY_PAGE,
  axiosPostWrapper,
  OVERVIEW_PAGE,
  QUESTIONS_PAGE,
} from './settings';

export default {
  name: 'HonoreeFormSurveyPage',
  components: {
    HonoreeFormInputField,
  },
  props: {
    allowedSteps: {
      type: Number,
    },
    settings: {
      type: Object,
    },
    translations: {
      type: Object,
    },
    formLabels: {
      type: Object,
    },
    formId: {
      type: String,
    },
    initialData: {
      type: Object,
    },
  },
  data() {
    return {
      step: SURVEY_PAGE,
      surveyAnswers: this.initialData.data.survey,
      otherSourcesInputValue: this.initialData.data.otherSourcesInputValue,
      socialMediaInputValue: this.initialData.data.socialMediaInputValue,
      optInMailingWowVolunteeringValue: this.initialData.data.optInMailingWowVolunteeringValue,
      optInMailingLorealSocietyValue: this.initialData.data.optInMailingLorealSocietyValue,
    };
  },
  computed: {
    checkboxDisabled() {
      return this.surveyAnswers.length >= 9999;
    },
    checkboxIsValid() {
      return this.surveyAnswers.length >= 1 && this.surveyAnswers.length <= 9999;
    },
    isOtherSelected() {
      return this.surveyAnswers.includes('Other');
    },
    isSocialMediaSelected() {
      return this.surveyAnswers.includes('SocialMedia');
    },
  },
  methods: {
    triggerValidation() {
      eventBus.emit(VALIDATE_CHILD_EVENT);
    },
    handleFormSubmit(e) {
      eventBus.emit(VALIDATE_CHILD_EVENT);

      nextTick().then(() => {
        const formValid = this.surveyAnswers.length > 0;

        const data = this.gatherData(true);
        if (formValid) this.submitData(data);
      });

      e.preventDefault();
    },
    handleFormSave() {
      eventBus.emit(VALIDATE_CHILD_EVENT);

      nextTick().then(() => {
        const data = this.gatherData();
        this.submitData(data);
      });
    },
    submitData(data) {
      axiosPostWrapper(this.settings.webserviceUrl, JSON.stringify(data)).then((response) => {
        if (data.incrementLocalStep) this.$emit('nextClick', OVERVIEW_PAGE);
        this.$emit('onDataSubmit', response);
      });
    },
    gatherData(incrementLocalStep = false) {
      const incrementAllowedStep = () => {
        return incrementLocalStep && this.step >= this.allowedSteps;
      };

      return {
        nominationId: this.formId,
        step: this.step,
        incrementLocalStep,
        incrementStep: incrementAllowedStep(),
        surveyInfo: {
          otherValue: this.isOtherSelected ? this.otherSourcesInputValue.value : false,
          socialMediaValue: this.isSocialMediaSelected ? this.socialMediaInputValue.value : false,
          surveyAnswers: this.surveyAnswers,
          optInMailingWowVolunteeringValue: this.optInMailingWowVolunteeringValue,
          optInMailingLorealSocietyValue: this.optInMailingLorealSocietyValue,
        },
      };
    },
    goToPreviousPage() {
      this.$emit('previousClick', QUESTIONS_PAGE);
    },
  },
};
</script>
