<template>
  <div class="honoree-form__text-group">
    <label :for="id" class="honoree-form__text__wrapper">
      {{ label }}
      <input
        :id="id"
        ref="input"
        v-model="data"
        class="honoree-form__text__input"
        type="text"
        :required="isRequired"
        :aria-invalid="showErrorMessages"
        :maxlength="characterLimit"
        @input="atFieldInput"
      />
    </label>
    <span
      v-if="showErrorMessages || showMaxlengthMessage"
      :id="`${id}--error`"
      class="honoree-form__error-state"
      v-text="currentErrorMessage"
    />
  </div>
</template>

<script>
import HonoreeFieldMixins from './HonoreeFieldMixins';

export default {
  name: 'HonoreeFormInputField',
  mixins: [HonoreeFieldMixins],
  props: {
    isEmail: {
      type: Boolean,
      default: false,
    },
    invalidMessage: {
      type: String,
      default: 'Please inform a valid email',
    },
    maxCharacterMessage: {
      type: String,
      default: '',
    },
    characterLimit: {
      type: Number,
      default: null,
    },
  },
  computed: {
    currentErrorMessage() {
      if (this.showInvalidErrorMessage) {
        return this.invalidMessage;
      }

      if (this.showMaxlengthMessage) {
        return this.maxCharacterMessage;
      }

      return this.errorMessage;
    },
    isValidEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.data).toLowerCase());
    },
    showInvalidErrorMessage() {
      if (this.pristine) return false;
      if (!this.data || !this.data.length > 0) return false;
      if (this.isEmail) return !this.isValidEmail;

      return false;
    },
    showMaxlengthMessage() {
      return this.characterLimit === this.data.length;
    },
    isValid() {
      if (!this.isRequired) return true;
      if (this.isEmail) return this.isValidEmail;

      return this.data != undefined && this.data.toString().trim() !== '';
    },
  },
};
</script>
